import SideBar_Item from "./SideBar_Item";
import { settings } from "../config";

export default function SideBar ( ) {

  let colors = ["#fcde9c","#6153cc","#3ab795","#e5446d","#55dde0"];

  return (
    <nav className="sidebar" id="sidebar">
      <div>
        { settings &&
          settings.navigation.map(( item ) => (
            < SideBar_Item
              is_category = { item.isCategory }
              title = { item.title }
              goto = { item.goto }
              color = { colors[ (item.key % colors.length) ] }
              icon = { item.icon }
              childs = { item.childs }
              key = { item.key }
              />
          ))
        }
      </div>
      <div className="username">expre</div>
    </nav>
  );
}