export const settings = {
  navigation:[
    { isCategory:true, title:'Navigation', goto:null, icon:null, childs:null, key:0 },
    { 
      isCategory:false, title:'Vec Control', goto:null, icon:'signal', childs:[
        { title:'overview', link:'/feed overview', key:0 },
        { title:'modifica', link:'/feed overview', key:1 }
      ],
      key:1
    },
    { 
      isCategory:false, title:'superadmin Control', goto:null, icon:'radar',
      childs:[
        { title:'overview', link:'/feed overview', key:0 },
        { title:'database', link:'/DBAction', key:1 }
      ],
      key:2
    }
  ],
}