import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function SideBar_Item ({ is_category, title, goto, color, icon, childs }) {

  const [ collapsed, setCollapsed ] = useState( false );
  const [ active, setActive ] = useState( false );
  const Nav = useNavigate();

  return (
    <>

    { is_category ?

      <div className="nav-item">
        <span className="nav-title"><b>{ title }</b></span>
      </div>

      :

      <>
      <a className={ active ? "nav-item active" : "nav-item" } onClick={ () => { toggleActive(); !childs && Nav( '/' ) ; } }>

        { icon && <i className={`mdi mdi-${ icon }`} style={{ color:color }}></i>}
        
        <div className="nav-title" aria-expanded='true'>{ title }</div>

        { childs && <i className="nav-arrow mdi mdi-arrow-down" onClick={() => toggleCollasped() }></i> }
      </a>

      { childs &&
      <div className={ collapsed ? "nav-sub nav-sub-open" : "nav-sub nav-sub-close" }>
        {
        childs.map(( child ) => (

          <a className="nav-title" onClick={ () => Nav( child.Link ) } key={ child.key }>
              { child.title }
          </a>

        ))
        }
      </div>
      }
      </>

    }
    
    </>
  );



  function toggleCollasped ( ) {
    collapsed ? setCollapsed( false ) : setCollapsed( true );
  }

  function toggleActive ( ) {
    active ? setActive( false ) : setActive( true );
  }


}