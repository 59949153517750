import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import SideBar from './frames/SideBar';
import FeedOverview from './view/FeedOverview';

function App() {
  return (
    < BrowserRouter >
    <div className="App">
      <div className='navbar'></div>
      <div className='container body'>
        < SideBar />

        <div className='content'>
            < Routes >

              < Route
                exact
                path = '/'
                element = { < FeedOverview /> }
                />

              < Route
                exact
                path = '/feed overview'
                element = { < FeedOverview /> }
                />

            </ Routes >
        </div>
      </div>
    </div>
    </ BrowserRouter >
  );
}

export default App;
